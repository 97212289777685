import logo from './logo.svg';
import './styles/globals.scss'
import './App.scss';
import Sicherheitsdiagnostik from './pages';
import react , {useState } from 'react'
import Footer from './components/footer';
import { motion, AnimatePresence } from 'framer-motion'
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";



function App() {

  const fadeInUp = {
    initial: {
      y: 0,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: [0.6, -0.05, 0.01, 0.99]
        //   staggerChildren: 0.1
      }
    },
    exit: { y: 0, opacity: 0 }
  }


 

  return (
    <div className="App" style={{ minHeight: "100vh", display: 'flex', flexDirection: "column", maxWidth: "100%" }}>
       <motion.div
              key="modal"
              variants={fadeInUp}
              initial='initial'
              animate='animate'
              exit='exit'
              className="w-100"
              style={{ flex: 1, marginTop: 50, maxWidth: "100%" }}
            >
                 <Sicherheitsdiagnostik />
                 <Footer />
            </motion.div>
   
    </div>
  );
}

export default App;
