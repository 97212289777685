
import React from 'react'
import { BsLinkedin , BsInstagram , BsTwitter  } from 'react-icons/bs'
import { FaPhoneAlt , FaEnvelope , FaInfo} from 'react-icons/fa'


export default function Footer() {

   



    return (
        <main className="bg-light px-md-5 py-5">
            <div className="container">
                <div className="row justify-content-around w-100">
                    <div className="col-12 mb-5 d-flex flex-column justify-content-around">
                      
                        <img className='' src="/idd2.png" width={"175px"} alt="" />
                        
                       
                    </div>
                    <div className="col-6 col-md-3">
                        <h6><b>Patienten</b></h6>
                        <ul style={{ listStyle: "none", padding: 0 }}>

                            <li className="pointer text-dark"><a href='https://ingenium-labs.de/booking'>Test Buchen</a></li>
                            {/* <li className="pointer text-dark"><a href="https://ergebnis.ingenium-labs.de">Kunden Log in</a></li> */}
                            <li className="pointer text-dark"><a href="https://ingenium-labs.de/userarea">Ergebnis abrufen</a></li>
                            {/* <li className="pointer" onClick={() => router.push('/missingsample2')}></li> */}
                            <li className="pointer" ><a  href="https://ingenium-labs.de/missingsample2">Auftrag stornieren</a></li>
                            <li className="pointer" ><a href='https://ingenium-labs.de/kitstatus'>Auftragsstatus prüfen</a></li>
                            <li className="pointer" ><a href='https://ingenium-labs.de/contact'>Hilfe und Kontakt </a></li>
                            {/* <li className="pointer" onClick={() => router.push('/contact')}>Kontakt </li> */}
                            {/* <li className="pointer text-dark">Linda</li> */}
                        </ul>
                    </div>
                    <div className='row d-md-none col-6'>
                        <div className="">
                            <h6><b>Ingenium Labs</b></h6>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                                <li className='pointer' ><a href='https://ingenium-labs.de/about'>Über uns</a></li>
                                <li className="pointer" ><a href="tel:069348709060">Service-Hotline</a> </li>
                                <li className="pointer" ><a href="mailto:service@ingeniumdx.com">Service E-Mail-Adresse</a> </li>
                               
                                {/* <li onClick={() => router.push("/about/team")}>Management Team</li> */}
                                {/* <li onClick={() => router.push("/about")}>Unternehmensdatenblatt</li> */}
                            </ul>
                        </div>
                        <div className="">
                            <h6><b>Partner</b></h6>
                            <ul style={{ listStyle: "none", padding: 0 }}>
                                <li className="pointer text-dark"><a href='https://ingenium-labs.de/partner'>Partner-Programm</a></li>
                                {/* <li className="pointer text-dark" onClick={() => router.push("/")}>Enroll partnership</li> */}
                                <li className="pointer text-dark" ><a href="https://partner.ingenium-labs.de"> Partner-Portal</a></li>
                                {/* <li className="pointer" ><a href="mailto:partner@ingeniumdx.com">Partner-Kontakt</a> </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className=" d-none d-md-block col-3 col-md-3">
                        <h6><b>Kontakt</b></h6>
                        <ul style={{ listStyle: "none", padding: 0 }}>
                            <li className='pointer' ><a href='https://ingenium-labs.de/about'><FaInfo/>  Über uns</a></li>
                            <li className="pointer" ><a href="tel:069348709060"><FaPhoneAlt/>  069-348709060</a></li>
                                <li className="pointer" ><a href="mailto:service@ingeniumdx.com"><FaEnvelope />  service@ingeniumdx.com</a> </li>
                            {/* <li onClick={() => router.push("/about/team")}>Management Team</li> */}
                            {/* <li onClick={() => router.push("/about")}>Unternehmensdatenblatt</li> */}
                        </ul>

                    </div>
                    <div className="d-none d-md-block col-3 col-md-3">
                        <h6><b>Partner</b></h6>
                        <ul style={{ listStyle: "none", padding: 0 }}>
                            <li className="pointer text-dark"><a href='https://ingenium-labs.de/partner'>Partner-Programm</a></li>
                            {/* <li className="pointer text-dark" onClick={() => router.push("/")}>Enroll partnership</li> */}
                            <li className="pointer text-dark" ><a href="https://partner.ingenium-labs.de"> Partner-Portal</a></li>
                            {/* <li className="pointer" ><a href="mailto:partner@ingeniumdx.com">Partner-Kontakt</a> </li> */}
                        </ul>
                    </div>

                    <div className="d-none d-md-block col-3 col-md-3">
                        <h6><b>Mitglied bei:</b></h6>
                        <ul style={{ listStyle: "none", padding: 0 }}>
                            <li > <img className='' src="/bio_deutschland.png" width={'70%'} style={{objectFit: 'contain'}}  alt="bio-deutschland" /></li>
                            {/* <li className="pointer text-dark" onClick={() => router.push("/")}>Enroll partnership</li> */}
                            <li>   <img className='' src="/l-covid_footer_logo.png" width={'70%'} style={{objectFit: 'contain'}} alt="Long-Covid-Ärzteverband" /></li>
                            {/* <li className="pointer" ><a href="mailto:partner@ingeniumdx.com">Partner-Kontakt</a> </li> */}
                        </ul>
                    </div>

                </div>
                <hr />

                <div className="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-center flex-column flex-md-row">
                    <span style={{ flex: 1, fontWeight: 200, fontSize: 12 }}>Copyright © 2022 Ingenium Labs GmbH | Weismüllerstr. 45 60314 Frankfurt a.M Deutschland</span>
                    <div className="d-flex justify-content-end align-items-end" style={{ fontSize: 12, fontWeight: 300, flex: 1, }}>
                        <span className="me-2 pointer" ><a href='https://ingenium-labs.de/agb'>AGB |</a></span>
                        <span className="me-2 pointer" ><a href='https://ingenium-labs.de/impressum'>Impressum |</a></span>
                        <span className="me-2 pointer" ><a href='https://ingenium-labs.de/pp'>Datenschutz-Bestimmungen |</a></span>
                        <span className="me-2 pointer" ><a href='https://ingenium-labs.de/widerrufsbelehrung'>Widerrufsrecht |</a></span>
                    </div>
                </div>
            </div>

        </main>
    )
}