import react, { useEffect, useState } from 'react'
import { BsCheck2 } from 'react-icons/bs'
import QRCode from "react-qr-code";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"

export default function Sicherheitsdiagnostik() {




    return (
        <div className='lcovid3'>

            {/* css class aus lcovid3 , sicherheitsdiagnostik klasse  */}
            <section className="sicherheitsdiagnostik position-relative d-none d-xxl-flex" style={{ maxHeight: 1000 }}>
                <div className="overla w-100 h-100">
                    <div className=" w-100 h-100 text-body" >
                        <div class="col-md-6">
                            <div className='' style={{ marginLeft: "2rem", marginTop: '2rem' }}>
                                <div className='d-flex flex-column justify-content-start align-items-start'>

                                    <h2 className="p-3 font2 " style={{ flex: 1, fontSize: 30 }}>Sie können Ihr OP-Risiko vor<br /> einem geplanten Eingriff senken.</h2>

                                    <ul style={{ align: 'left', flex: 1 }} className='p-1 ml-0 wodots font3 ps-5'>
                                        <li><span><BsCheck2 color="white" size={50} /></span><span style={{ fontSize: 22 }} className="text-white font3 ps-3">Mit wenigen Bluttropfen</span></li>
                                        <li><span><BsCheck2 color="white" size={50} /></span><span style={{ fontSize: 22 }} className="text-white font3 ps-3">Von zu Hause aus &nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                                        <li><span><BsCheck2 color="white" size={50} /></span><span style={{ fontSize: 22 }} className="text-white font3 ps-3">Wir helfen Ihnen dabei &nbsp;</span> </li>

                                        <a href="https://ingenium-labs.de/booking/product?test=19" target='_blank'>
                                            <button type="button" class="btn btn-color5 btn-lg mr-md-3 mb-md-0 mb-2">Zum OP-Checkup</button>
                                        </a>


                                    </ul>


                                </div>
                            </div>

                            <div className='qrBox'>

                                <h1>Zum Check-up vor OP</h1>
                                <div className='d-flex justify-content-around'>
                                    <h6>Direkt zum Check</h6>
                                    <img src="/:long-covid/hand-left.svg" alt="" width={50} />
                                    <div className='p-2'>
                                        <QRCode value='op-checkup.de' bgColor='transparent' size='100' />
                                    </div>
                                </div>
                                <a href="www.op-checkup.de">www.op-checkup.de</a>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="position-relative leftside3" style={{ flex: 1 }}>
                    <div className="position-absolute content w-100 d-flex align-items-end flex-column">
                        <nav style={{zIndex: '999'}}>
                            <img src="/logo_wh.svg" alt="ingenium labs" />
                            <ul>

                                <li style={{ fontSize: '0.8rem', marginRight: '3rem' }}><a  href="https://ingenium-labs.de/booking/product?test=19" target='_blank' style={{ cursor: 'pointer' }}>KONTAKT</a></li>

                            </ul>
                        </nav>
                    </div>
                </div>
                <div className=" rightside3" style={{ flex: 1, zIndex: '-2' }}>
                    <img width="100%" height='100%' style={{ objectFit: "cover" }} src="/doctorwoman.jpg" alt="Long-Covid" />
                    <div className='Box'>
                        <div className=''>
                            <h1>Check-up vor OP</h1>
                            <p>(Patienten Blutmanagement)</p>
                            <br />
                            <p>Sie Ihr Operationsrisiko.</p>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img src="/logo_wh.svg" alt="ingenium labs" />
                        </div>
                        
                    </div>
                </div>
            </section>




            <section className="middle1neu3 d-flex flex-column d-xxl-none">
                <div className="rightside" style={{ flex: 1 }}>

                    <img width="100%" height={"100%"} style={{ objectFit: "cover", objectPosition: "45% 20%" }} src="/doctorwoman.jpg" alt="Long-Covid" />
                </div>


                <div className="position-relative leftside " >
                    <div className="overla ">
                        <div className="container w-100 h-100 pt-4 text-body" >
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div style={{ marginLeft: 20}}>
                                 
                                <h1 className="font2 p-2 " style={{ fontSize: 33 }}>Sie können Ihr<br /> OP-Risiko vor<br /> einem geplanten<br /> Eingriff senken.</h1>
                                <ul style={{ alignSelf: 'left' }} className='p-3  wodots font3 ps-5'>
                                    <li style={{ alignItems: 'center', display: 'block', textAlign: 'left' }}><span><BsCheck2 color="white" size={30} /></span><span style={{ fontSize: 22 }} className="text-white font3 ps-3">Mit wenigen Bluttropfen</span></li>
                                    <li style={{ alignItems: 'center', display: 'block', textAlign: 'left' }}><span><BsCheck2 color="white" size={30} /></span><span style={{ fontSize: 22 }} className="text-white font3 ps-3">Von zu Hause aus</span></li>
                                    <li style={{ alignItems: 'center', display: 'block', textAlign: 'left' }}><span><BsCheck2 color="white" size={30} /></span><span style={{ fontSize: 22 }} className="text-white font3 ps-3">Wir helfen Ihnen dabei</span> </li>

                                </ul>
                                <div className='d-flex flex-row justify-content-center align-items-start'>
                                    <a href="https://ingenium-labs.de/booking/product?test=19" target='_blank'>

                                        <button type="button" class="btn btn-color5 btn-lg mr-md-3 mb-md-0 mb-2">Zum OP-Checkup</button>
                                    </a>
                                </div>
                            </div>
                            </div>

                        </div>

                    </div>
                    <div className="position-absolute content w-100 d-flex align-items-end flex-column">

                        <section className="centeredconti3 " >
                            <div className="containeri">
                                <div className="row bg-white w-100" >
                                    <div className="col-12 col-md-6 p-5">
                                        <h2>Welche Folgen<br />kann Long-Covid<br />für mich haben?</h2>
                                        <p className="d-flex p-2 align-items-end pitua">Als „Long-Covid“ wird die Vielzahl von Folgeerkrankungen nach einer Corona-Infektion bezeichnet. <br />  <br /> Diese bleiben nicht selten unerkannt und können zu schwerwiegenden und langfristigen Gesundheitsproblemen führen.​</p>
                                    </div>
                                    <div className="col-12 col-md-6 p-5">
                                        <h2>Was kann ich<br />aktiv tun?</h2>
                                        <p className="d-flex  p-2 align-items-end pitua">Durch das frühzeitige Erkennen von Long-Covid können Folgeschäden vermindert oder verhindert werden. <br /> <br /> Mit Hilfe unserer Experten-Fragebögen beurteilen wir Ihr Risiko für Long-Covid. <br /><br /> Bitte beachten Sie: Unsere ärztliche Bewertung erfolgt ausschließlich auf Basis Ihrer korrekten Angaben. Es erfolgt im ersten Schritt keine Diagnosestellung durch uns. Die Fragebögen ersetzen nicht den persönlichen Arztbesuch.​</p>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </section>


            <section className='row glassBox '>
                {/* <img style={{ objectFit:'contain', zIndex: 0, maxHeight:'119%', width:'100%' }} src="/lcovid2/glassware.jpg" alt="Glass" /> */}
                <div className='col-xl-6 p-5' style={{ zIndex: '1' }}>
                    <div>
                        <h2>Check-up vor OP</h2>
                        <h6>(Patienten Blutmanagement)</h6>
                        <br />
                        <h4>Sie stehen vor einem geplanten<br />chirurgischen Eingriff ?</h4>
                        <p style={{ textAlign: 'justify' }}>Viele Fachkliniken sind heute bestrebt, ihre Patienten durch das sog. „Patienten Blutmanagement“ bestmöglich auf einen geplanten operativen Eingriff vorzubereiten. Damit werden mit dem Eingriff verbundene Komplikationsrisiken deutlich vermindert und unnötig lange Krankenhausaufenthalte vermieden. Unser „Check-up vor OP“ hilft Ihnen vor Ihrem Eingriff herauszufinden, ob die beiden wichtigsten Risikofaktoren für Komplikationen – Blutarmut (Anämie) und Diabetes - bei Ihnen vorliegen. Wir verwenden hierzu ein innovatives Verfahren, für das lediglich wenige Bluttropfen aus der Fingerkuppe erforderlich sind. Sie können die Probenabnahme mithilfe unserer zertifizierten Probenabnahme-Box zu Hause erledigen, ohne die Notwendigkeit eines Arztbesuchs. Ihre Proben werden dann in unserem Facharztlabor analysiert. Im Fall von auffälligen Ergebnissen können Ihre behandelnden Ärzte vor Ihrer OP eine eventuell bestehende Blutarmut beheben oder einen Diabetes medikamentös günstig einstellen, damit Sie bestens für Ihren Eingriff vorbereitet sind.</p>
                    </div>
                    <div className='d-flex '>
                        <img width="50%" height={"50%"} style={{ objectFit: "cover", maxWidth: 800 }} src="/bg2.webp" alt="wolfgang" />
                        <footer style={{ alignSelf: 'flex-end', margin: '1rem', fontStyle: 'italic', textAlign: 'left' }} >Prof. Dr. med. Wolfgang Kaminski<br />Ärztlicher Leiter Ingenium Labs</footer>
                    </div>
                </div>
                <div className='col-xl-6 p-5 order-first order-xl-0' style={{ zIndex: '1' }}>
                    <div>
                        <h3>So gehen Sie vor:</h3>
                        <ul style={{ listStyleType: 'none' }}>
                            <li><div className='d-flex'><h1 style={{ color: 'rgb(255, 149, 204)', display: 'block', textAlign: 'left' }} className='numbers'>01</h1><h6 className='nText'>Wir senden Ihnen  die Blutabnahme- Box zu.</h6></div></li>
                            <li><div className='d-flex'><h1 style={{ color: 'rgb(255, 119, 204)', display: 'block', textAlign: 'left' }} className='numbers'>02</h1><h6 className='nText'>Probe abnehmen und per Post  an uns zurücksenden. </h6></div></li>
                            <li><div className='d-flex'><h1 style={{ color: 'rgb(205, 49, 204)', display: 'block', textAlign: 'left' }} className='numbers'>03</h1><h6 className='nText'>Sie erhalten unseren ärztlichen Befund innerhalb von wenigen Tagen.</h6></div></li>
                        </ul>
                    </div>
                    <div className='redBox'>
                        <h6 style={{ fontWeight: 'bold', textAlign: 'justify' }}>Hämoglobin</h6>
                        <p style={{ textAlign: 'justify' }}>Eine Blutarmut (Anämie) kann durch Bestimmung von Hämoglobin erkannt werden. Etwa jeder vierte Mensch leidet daran, etwa die Hälfte davon infolge von Eisenmangel. Ein Eisenmangel kann einfach behoben werden. Das Risiko an einem größeren operativen Eingriff zu versterben ist bei Blutarmut vierfach erhöht. Ein ähnlich hohes Risiko besteht für die Notwendigkeit einer Bluttransfusion oder Nierenschäden.</p>
                        <h6 style={{ fontWeight: 'bold', textAlign: 'justify' }}>HbA1c</h6>
                        <p style={{ textAlign: 'justify' }}>Dieser Blutfaktor zeigt an, wie hoch Ihr Blutzucker während der letzten Wochen war. Ein bislang noch nicht erkannter Diabetes oder eine unzureichende Blutzuckereinstellung bei bekanntem Diabetes können damit entdeckt werden. Dies ist bei chirurgischen Eingriffen von großer Bedeutung, da beide Zustände mit einer Vielzahl von OP-Komplikationen wie Wundinfektionen, Lungenentzündungen und der Notwendigkeit intensivmedizinischer Behandlung einhergehen können.</p>
                    </div>
                </div>
            </section>

            <section className='separator bg-light py-5'>
                <a href="https://ingenium-labs.de/booking/product?test=19" target='_blank'>
                    <button type="button" class="btn btn-color5 btn-lg mr-md-3 mb-md-0 mb-2">Zum OP-Checkup</button>
                </a>
            </section>


        </div>
    )

}